import React, { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import StatsIllustrationSrc from "images/stats-illustration.svg";
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto relative`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Statistics = tw.div`flex flex-col items-center sm:block text-center md:text-left mt-4`;
const Statistic = tw.div`text-left sm:inline-block sm:mr-12 last:mr-0 mt-4`;
const Value = tw.div`font-bold text-lg sm:text-xl lg:text-2xl text-secondary-500 tracking-wide`;
const Key = tw.div`font-medium text-primary-700`;

const Features = tw.div`mt-8 mx-auto md:mx-0`;
const Feature = tw.div`mt-8 flex items-start flex-col md:flex-row`;

const FeatureIconContainer = styled.div`
  ${tw`mx-auto inline-block border border-primary-500 text-center rounded-full p-2 flex-shrink-0`}
  svg {
    ${tw`w-5 h-5 text-primary-500`}
  }
`;
const UseCasesText = tw.div`font-bold text-lg text-primary-500`;
const FeatureText = tw.div`mt-4 md:mt-0 md:ml-4 text-center md:text-left`;
const FeatureHeading = tw.div`font-bold text-lg text-secondary-400`;
const FeatureDescription = tw.div`mt-1 text-sm`;

const PrimaryButton = tw(
  PrimaryButtonBase
)`mt-8 md:mt-10 text-sm inline-block mx-auto md:mx-0`;

const DecoratorBlob = styled(SvgDotPattern)((props) => [
  tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`,
]);

export default ({
  subheading = "ABOUT FLOODRUNNER",
  // heading = (
  //   <>
  //     <span tw="text-primary-500">FloodRunner</span> is designed for browser
  //     testing
  //   </>
  // ),
  heading = (
    <>
      We believe in <span tw="text-primary-500">simplifying monitoring</span>
    </>
  ),
  description = `
    As your production systems scale and you add more functionality, testing becomes ever more critical
     to ensure that everything is running smoothly.     
  `,
  primaryButtonText = "Learn More",
  primaryButtonUrl = "https://timerse.com",
  imageSrc = StatsIllustrationSrc,
  imageCss = null,
  imageContainerCss = null,
  imageDecoratorBlob = false,
  imageDecoratorBlobCss = null,
  imageInsideDiv = true,
  statistics = null,
  textOnLeft = false,
  id = "mainFeature",
}) => {
  const [seen, setSeen] = useState(false);
  /*
   * Change the features variable as you like, add or delete objects
   * `icon` must be a React SVG component. See how BriefcaseIcon is imported above. For a full list of available icons, see Feather Icons.
   */
  const features = [
    {
      Icon: MoneyIcon,
      title: "Affordable",
      description:
        "We promise to offer you the best rate we can - at par with the industry standard.",
    },
  ];

  const defaultStatistics = [];

  if (!statistics) statistics = defaultStatistics;

  return (
    <Container>
      <TwoColumn id={id} css={!imageInsideDiv && tw`md:items-center`}>
        <ImageColumn css={imageContainerCss}>
          {imageInsideDiv ? (
            <Image imageSrc={imageSrc} css={imageCss} />
          ) : (
            <img src={imageSrc} css={imageCss} alt="" />
          )}
          {imageDecoratorBlob && <DecoratorBlob css={imageDecoratorBlobCss} />}
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            <Description>
              FloodRunner allows you to monitor your web applications using
              browser tests, written in Puppeteer or Element, easily simulating
              user behaviour on your applications.
              <br />
              <br />
              Browser tests allow you to truly understand the experience your
              users have when interacting with your web app. They are simple to
              script, highly readable and most importantly, they abstract all
              the complex interactions of your backend services, making testing
              simple and putting the focus on your user's experiences.
              <br />
              <br />
              <UseCasesText>What can browser tests be used for? </UseCasesText>
            </Description>

            <Features>
              <Feature>
                <FeatureText>
                  <FeatureHeading>Critical User Paths</FeatureHeading>
                  <FeatureDescription>
                    Simulate users' loging in to your system or browsing an
                    online store and checking out.
                  </FeatureDescription>
                </FeatureText>
              </Feature>
              <Feature>
                <FeatureText>
                  <FeatureHeading>Faking Geolocation</FeatureHeading>
                  <FeatureDescription>
                    Analyze the experience of users from all around the world.
                  </FeatureDescription>
                </FeatureText>
              </Feature>
              <Feature>
                <FeatureText>
                  <FeatureHeading>Performance Tuning/Monitoring</FeatureHeading>
                  <FeatureDescription>
                    Evaluate and monitor your websites performance and ensure
                    your site is always highly responsive.
                  </FeatureDescription>
                </FeatureText>
              </Feature>
            </Features>

            <Statistics>
              {statistics.map((statistic, index) => (
                <Statistic key={index}>
                  <Value>{statistic.value}</Value>
                  <Key>{statistic.key}</Key>
                </Statistic>
              ))}
            </Statistics>
            {/* <PrimaryButton as="a" href={primaryButtonUrl}>
              {primaryButtonText}
            </PrimaryButton> */}
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};

import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import {
  SectionHeading as HeadingTitle,
  Subheading as SubheadingBase,
} from "../misc/Headings.js";

import testOverview from "../../images/floodrunner/test-overview.svg";
import testExecutionTimeFull from "../../images/floodrunner/test-execution-time-full.svg";
import testExecutionTimePartial from "../../images/floodrunner/test-execution-time-partial.svg";
import testLogs from "../../images/floodrunner/test-logs.svg";
const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(HeadingTitle)`w-full`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Content = tw.div`mt-16`;

const Card = styled.div((props) => [
  tw`mt-16 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row",
]);
const ScreenshotImage = styled.div((props) => [
  `background-image: url("${props.imageSrc}");
   height: 32em !important;
  `,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-144 bg-cover bg-center bg-repeat-round mx-4 sm:mx-8 md:mx-4 lg:mx-8`,
]);
const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");
     height: 24em !important;
    `,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-144 bg-cover bg-center bg-repeat-round mx-4 sm:mx-8 md:mx-4 lg:mx-8`,
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;
const Link = tw.a`inline-block mt-4 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

export default ({ subheading, heading, id = "features" }) => {
  const cards = [
    {
      imageSrc: testOverview,
      subtitle: "",
      title: "Simple to setup and check",
      description: `
        Schedule your browsers test with ease using Puppeteer or Element.
        Your dashboard then allows you to quickly view the status of all tests at a glance.
        `,
      url: "https://timerse.com",
    },

    {
      imageSrc: testExecutionTimePartial,
      subtitle: "",
      title: "Quickly view the history of any test",
      description: `
        The history of each test is shown in a clear graph that quickly points out the status of each test run as well
        as the execution time. Visualizing the execution time of your test runs will allow you to easily spot any
        performance issues.
        `,
      url: "https://timerse.com",
    },

    {
      imageSrc: testLogs,
      subtitle: "",
      title: "Capture detailed screenshots and logs",
      description: `
        Any logs or screenshots taken by your test are shown with each test run, making it easy for you to spot when something goes wrong in your application.
      `,
      url: "https://timerse.com",
    },
  ];

  return (
    <Container id={id}>
      <SingleColumn>
        <HeadingInfoContainer>
          <Subheading> {subheading} </Subheading>
          <HeadingTitle>{heading}</HeadingTitle>
        </HeadingInfoContainer>
        <Content>
          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1}>
              {i == 2 ? (
                <ScreenshotImage imageSrc={card.imageSrc} />
              ) : (
                <Image imageSrc={card.imageSrc} />
              )}

              <Details>
                <Subtitle>{card.subtitle}</Subtitle>
                <Title>{card.title}</Title>
                <Description>{card.description}</Description>
                {/* <Link href={card.url}>See Event Details</Link> */}
              </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>
      <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 />
    </Container>
  );
};

import React from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "./components/hero/TwoColumnWithInput.js";
import Features from "./components/features/ThreeColWithSideImage.js";
import VerticalFeatures from "./components/features/VerticalWithAlternateImageAndText.js";
import FeatureWithSteps from "./components/features/TwoColWithSteps.js";
import Pricing from "./components/pricing/ThreePlans.js";
import FAQ from "./components/faqs/SingleCol.js";
import GetStarted from "./components/cta/GetStartedLight";
import Footer from "./components/footers/MiniCenteredFooter.js";
import ContactForm from "./components/forms/TwoColContactUsWithIllustrationFullForm";
import macHeroScreenshotImageSrc from "images/floodrunner/results-page.svg";

import MainFeature from "./components/features/TwoColSingleFeatureWithStats2.js";

const About = MainFeature;

export default ({}) => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage disabled>
      <Hero roundedHeaderButton={true} />
      <About id="about" />
      {/* <Features
        subheading={<Subheading> Features </Subheading>}
        heading={
          <>
            All-in-one <HighlightedText> monitoring platform. </HighlightedText>{" "}
          </>
        }
        description=""
      /> */}
      <VerticalFeatures
        subheading={<Subheading> Features </Subheading>}
        heading={
          <>
            All-in-one <HighlightedText> monitoring platform. </HighlightedText>{" "}
          </>
        }
      />
      <FeatureWithSteps
        subheading={<Subheading> STEPS </Subheading>}
        heading={
          <>
            Easy to <HighlightedText> get started. </HighlightedText>{" "}
          </>
        }
        textOnLeft={false}
        imageSrc={macHeroScreenshotImageSrc}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      />{" "}
      <Pricing
        subheading={<Subheading> Pricing </Subheading>}
        heading={
          <>
            Reasonable & flexible <HighlightedText> plans. </HighlightedText>{" "}
          </>
        }
        description=""
        plans={[
          {
            name: "Personal",
            price: "$0",
            duration: "FREE FOREVER",
            mainFeature: "For Hobbyists",
            features: ["1 testing hour", "60 Minute interval"],
            primaryButtonText: "Get Started!",
            primaryButtonLinkUrl: "https://app.floodrunner.dev",
          },
          {
            name: "Business",
            price: "$10",
            duration: "Monthly",
            mainFeature: "For Small Businesses",
            features: ["40 testing hours", "30 Minute interval"],
            primaryButtonText: "Get Started!",
            primaryButtonLinkUrl: "https://app.floodrunner.dev",
            featured: true,
          },
          {
            name: "Enterprise",
            price: "$100",
            duration: "Monthly",
            mainFeature: "For Large Businesses",
            features: [
              "400 testing hours",
              "10 Minute interval",
              "Support",
              // "Email alerts",
              // "Flood.io load test integration",
              // "API access",
            ],
            primaryButtonText: "Get Started!",
            primaryButtonLinkUrl: "https://app.floodrunner.dev",
          },
        ]}
      />
      <FAQ
        subheading={<Subheading> FAQS </Subheading>}
        heading={
          <>
            You have <HighlightedText> questions ? </HighlightedText>
          </>
        }
        description="Let us guide you."
        faqs={[
          {
            question: "What is Puppeteer?",
            answer: (
              <div>
                Puppeteer is an open source library developed by Google that was
                built with the purpose of automating and simplifying frontend
                tests and development. It is based on chromium (also supports
                Firefox) and can be 100% remotely controlled, allowing web
                developers to write and maintain simple, fully automated tests.
              </div>
            ),
            buttonText: "Read more about Puppeteer",
            buttonLink: "https://pptr.dev/",
          },
          {
            question: "What is Flood Element?",
            answer: (
              <div>
                Flood Element is an open-source language designed to make
                browser based load testing as simple as functional testing. It
                is normally used with the Flood.io platform to perform load
                tests but with FloodRunner you can also use it for scheduled
                functional testing.
              </div>
            ),
            buttonText: "Read more about Flood Element",
            buttonLink: "https://element.flood.io/",
          },
          {
            question: "What does the minute interval mean?",
            answer: `This is the shortest interval on which you can schedule a test.
              This interval varies by plan as tests that run more often result in more system load.`,
          },
          {
            question: "What are testing hours?",
            answer: `This represents the time available for your test executions.
            At the beginning of every period you are allocated a certain number of testing hours
            based on your plan. Every time your tests execute, the total time taken to run them is counted against
            your available testing hours.`,
          },
          {
            question: "Its open-source, right?",
            answer: (
              <div>
                The core of FloodRunner is open-source under the Apache 2
                licence. Come check it out and contribute.
              </div>
            ),
            buttonText: "Checkout the GitHub repository",
            buttonLink: "https://github.com/FloodRunner/FloodRunner",
          },
          // {
          //   question: "When will the Personal and Business plan launch?",
          //   answer: `We're currently running a Beta of the FloodRunner platform to gain a better understanding
          //     of the key features our customers need. Once the Beta ends we will start launching the
          //     Personal and Business plan.`,
          // },
        ]}
      />
      <ContactForm />
      <GetStarted />
      <Footer />
    </AnimationRevealPage>
  );
};
